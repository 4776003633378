import { render, staticRenderFns } from "./SysPlatformMessage.vue?vue&type=template&id=0b676810&scoped=true&"
import script from "./SysPlatformMessage.vue?vue&type=script&lang=js&"
export * from "./SysPlatformMessage.vue?vue&type=script&lang=js&"
import style0 from "./SysPlatformMessage.vue?vue&type=style&index=0&id=0b676810&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b676810",
  null
  
)

export default component.exports