<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="4" :sm="24">
            <a-form-item label="标题">
              <a-input v-model="queryParam.title" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content', y: scrollHeight }"
    >
<!--      <span slot="content" slot-scope="text">-->
<!--        <ellipsis :length="20" tooltip>{{ text }}</ellipsis>-->
<!--      </span>-->

      <span slot="action" slot-scope="text, record">
        <template>
          <a :href="record.linkUrl" target="_blank">查看&ensp;</a>
        </template>
      </span>
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <!--      <span slot="action" slot-scope="text, record" >-->
      <!--          <template>-->
      <!--            <a @click="handleEdit(record)" v-show="record.readed==0">已读</a>-->
      <!--          </template>-->
      <!--        </span>-->
    </s-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode, defineComponent } from 'vue'
import { Modal } from 'ant-design-vue'
import {findPageNotice, findPagePlatformMessage} from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '标题',
          width: '150px',
          key: 'title',
          dataIndex: 'title',
          align: 'center',
        },
        {
          title: '内容',
          width: '350px',
          key: 'content',
          dataIndex: 'content',
          align: 'center',
          scopedSlots: { customRender: 'content' },
          // customRender: (text) => {
          //   if(text==null){
          //     text = ""
          //   }
          //   return <lable title={text}>{text}</lable>
          // }
        },
        {
          title: '类型',
          width: '100px',
          key: 'type',
          dataIndex: 'type',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 10) {
              ret = '客户端公告'
            } else if (text == 20) {
              ret = '司机端公告'
            } else {
              ret = '服务商公告'
            }
            return ret
          },
        },
        {
          title: '开始日期',
          width: '150px',
          key: 'startDate',
          align: 'center',
          dataIndex: 'startDate',
        },
        {
          title: '结束日期',
          width: '150px',
          key: 'endDate',
          align: 'center',
          dataIndex: 'endDate',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '180px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        parameter.type = 30
        parameter.recordState = 1
        parameter.onlineState = 1
        param = Object.assign(parameter, this.queryParam)
        return findPagePlatformMessage(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      let that = this
      Modal.confirm({
        title: '确认已读',
        // icon: createVNode(ExclamationCircleOutlined),
        content: '确认已读吗',
        onOk() {
          return new Promise((resolve, reject) => {
            // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            updateMessage({ id: record.id, readed: 1 }).then((res) => {
              that.$message.success('更新成功')
              that.$refs.table.refresh()
            })
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {},
      })
    },
    handleDelRole(record) {},
    handleOk() {},
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addRoleHandleCancel() {},
    handleAdd() {},
    handleSubmit(e) {
      e.preventDefault()
    },
    addRoleCancel() {},
    iscandel(record) {},
    bindpermHandleCancel() {},
    handleBind(e) {},
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
</style>